<template>
    <div 
        :key="$route.name" 
        class="layout">
        <component 
            v-if="asideType === 'mini'" 
            :is="'style'">
            :root {
            --wrapperLeftPadding: 80px
            }
        </component>
        <component 
            v-if="asideType === 'dynamic'" 
            :is="'style'">
            :root {
            --wrapperLeftPadding: 60px
            }
        </component>
        <component 
            v-if="asideType === 'big'" 
            :is="'style'">
            :root {
            --wrapperLeftPadding: 250px
            }
        </component>
        <component 
            v-else 
            :is="'style'">
            :root {
            --wrapperPadding: 50px
            }
        </component>
        <component 
            :is="'style'">
            :root {
            --asideWidth: {{ asideWidth }}px
            }
        </component>
        <component
            v-if="eyeVersion"
            :is="'style'">
            html{
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            filter: grayscale(100%);
            filter: url(grayscale.svg#greyscale); /* Firefox 4+ */
            filter: gray; /* IE 6-9 */
            -webkit-filter: grayscale(1);
            }
        </component>

        <!-- Header template -->
        <Header />

        <!-- Main wrapper template -->
        <main 
            class="main_wrapper flex overflow-hidden relative" 
            style="flex-grow: 1;">
            <!-- Sidebar template -->
            <template v-if="asideType !== 'header'">
                <keep-alive>
                    <component 
                        :is="sidebarTemplate" 
                        :config="config" />
                </keep-alive>
            </template>
            
            <div class="flex flex-col w-full">
                <div 
                    class="content_wrapper" 
                    style="flex-grow: 1;">
                    <router-view />
                </div>
            </div>
        </main>
        <SWUpdateBanner v-if="serverType !== 'dev'" />
    </div>
</template>

<script>
import Header from './components/Header'
import {mapState} from 'vuex'
import theme from './mixins/theme'
import SWUpdateBanner from '@/components/SWUpdateBanner.vue'
export default {
    mixins: [
        theme
    ],
    components: {
        Header,
        SWUpdateBanner
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            menu: state => state.navigation.menu,
            eyeVersion: state => state.eyeVersion,
            config: state => state.config.config,
            serverType: state => state.serverType,
            asideType: state => state.asideType
        })
    }
}
</script>

<style lang="scss" scoped>
.layout {
    display: flex;
    flex-direction: column;
    .main_wrapper{
        width: 100vw;
        padding-left: var(--wrapperLeftPadding);
    }
}
.content_wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
</style>