export default {
    computed: {
        deferredPrompt() {
            return this.$store.state.deferredPrompt
        },
        headerMenu() {
            if(this.asideType === 'header')
                return () => import(`./HeaderMenu/index.vue`)
            else
                return null
        },
        pwaBanner() {
            if(this.deferredPrompt)
                return () => import(`./PWABanner`)
            else
                return null
        },
        cart() {
            if(this.appConfig?.header_setting?.product_cart) {
                return () => import('./Cart.vue')
            } else
                return null
        },
        support() {
            if(this.appConfig?.header_setting?.support) {
                return () => import('./Support.vue')
            } else
                return null
        },
        /*returnWidget() {
            if(this.appConfig?.order_setting?.purchase_returns)
                return () => import(`./ReturnCart`)
            else
                return null
        },*/
        notification() {
            if(this.appConfig?.header_setting?.notification)
                return () => import(`./Notification`)
            else
                return null
        },
        calendar() {
            return this.appConfig?.header_setting?.calendar ? () => import(`./Calendar`) : null
        },
        /*i18nSwicth() {
            if(this.appConfig?.header_setting?.i18n)
                return () => import(`../i18n`)
            else
                return null
        }*/
    }
}